.google-map {
  height: 227px;
}

.address-book-map .google-map {
  height: 274px;
}

.google-map-directions {
  height: 420px;
}
